.text_block {
    height: 100%;
    padding-left: 24px;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #BCBEC3;
    display: flex;
    align-items: center;
}

.active .text_block{
    background: #ECECEC;
    color: #131313;
    width: 77%;
}

.inactive {

}

.inactive:hover .img_block {
    border-left: 2px solid #ECECEC;
}

.inactive:hover .text_block {
    background: rgba(236, 236, 236, 0.07);
    width: 77%;
}

.img_block {
    width: 74px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active .img_block {
    border-left: 2px solid #E2B778;

}

.img_details {
    width: 24px;
    height: 24px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../../assets/img/accounts_details.svg");
}

.active .img_details {
    background-color: #E2B778;
}

.img_statements {
    width: 24px;
    height: 24px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../../assets/img/statement.svg");
}

.active .img_statements {
    background-color: #E2B778;
}

.img_transfers {
    width: 24px;
    height: 24px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../../assets/img/transfers.svg");
}

.active .img_transfers {
    background-color: #E2B778;
}

.img_intra {
    width: 24px;
    height: 24px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../../assets/img/intra_transfer.svg");
}

.active .img_intra {
    background-color: #E2B778;
}

.img_exchange {
    width: 24px;
    height: 24px;
    background-color: #BCBEC3;
    display: block;
    mask-image: url("../../assets/img/currency_exchange.svg");
}

.active .img_exchange {
    background-color: #E2B778;
}
