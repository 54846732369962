.my_btn {
    padding: 14px 24px;
    background: #E2B778;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    transition: .5s all ease;
    cursor: pointer;
}

.my_btn:hover {
    background: #D6AD72;
    transition: .5s all ease;
}
