.title {
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
    margin-bottom: 40px;
}

.client_info {
    width: 70%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
}

.right {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.field_name {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    width: 48%;
}

.field_value {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #131313;
    width: 48%;
}

.accounts {

}

.accounts_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.accounts_title {
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
}

.accounts_table {
    width: 100%;
}

.my_select {
    margin-left: 8px;
    outline: none;
    text-decoration: none;
}

.table_header {
    display: flex;
    justify-content: space-between;
    background: #E2B778;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #131313;
    height: 44px;
    padding: 0 16px;
}

.header_item_long {
    width: 22%;
}

.header_item_status {
    width: 11%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header_item_short {
    width: 17%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.table_row {
    display: flex;
    justify-content: space-between;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #131313;
    height: 44px;
    padding: 0 16px;
}

.table_row:nth-child(odd) {
    background: #DDDDDD;
}

.currency {
    padding-right: 8px;
}
