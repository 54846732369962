.login_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url("../../assets/img/log_in_background.jpg");
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    transition: .5s all ease;
}

.login_wrapper_content_right {
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url("../../assets/img/log_in_background.jpg");
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    transition: .5s all ease;
}

.logo_block {
    position: absolute;
    top: 20px;
    left: 100px;
}

.sign_in_wrapper {
    width: 642px;
    height: 100vh;
    background: #131313;
    padding-left: 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.sign_in_content {
    width: 400px;
}

.title {
    font-family: 'Noto Serif Georgian', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.subtitle {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 80px;
}

.my_input {
    font-family: 'Century Gothic';
    width: 400px;
    height: 48px;
    background: #FFFFFF;
    padding: 14px 12px;
    box-sizing: border-box;
    outline: none;
    border: none;
    margin-top: 20px;
}

.error {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #EE5252;
    margin-top: 4px;
}

.btn_block {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

.my_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 156px;
    height: 48px;
    box-sizing: border-box;
    background: #E2B778;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: .5s all ease;
}

.my_btn:hover {
    background: #D6AD72;
    transition: .5s all ease;
}
