.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.content {
    width: 575px;
    height: 204px;
    background: #DDDDDD;
    padding: 16px;
    box-sizing: border-box;
}

.title {
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #EE5252;
    margin-top: 40px;
}

.close {
    display: flex;
    justify-content: flex-end;
}

.close img {
    cursor: pointer;
}
