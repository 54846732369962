.wrapper {

}

.statement_filters_wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 55px;
}

.filter_block {
    margin-right: 20px;
}

.filter_title {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    margin-bottom: 12px;
}

.statement_table {

}

.table_header {
    display: flex;
    justify-content: space-between;
    background: #E2B778;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #131313;
    min-height: 44px;
    padding: 0 16px;
}

.header_item_long {
    width: 11%;
}

.header_item_short {
    width: 8%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table_row {
    display: flex;
    justify-content: space-between;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #131313;
    min-height: 44px;
    max-height: fit-content;
    padding: 0 16px;
}

.table_row:nth-child(odd) {
    background: #DDDDDD;
}

.without_transactions {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
    margin-top: 60px;
}

.out_style {
    color: #EE5252;
}

.in_style {
    color: #29A662;
}
