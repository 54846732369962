.title {
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
    margin-bottom: 40px;
}

.info_wrapper {
    display: flex;
    justify-content: space-between;
}

.block {
    width: 40%;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
}

.field_name {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    width: 48%;
}

.field_value {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #131313;
    width: 48%;
}
