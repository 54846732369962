.input_block {
    margin-right: 20px;
    margin-bottom: 20px;
}

.input_title {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    margin-bottom: 12px;
}

.my_input {
    width: 376px;
    height: 48px;
    background: #FFFFFF;
    outline: none;
    border: none;
    font-family: 'Century Gothic', serif;
    font-size: 16px;
}

.inputs_wrapper {
    display: flex;
}

.current_currency {
    width: 84px;
    background: #DDDDDD;
    padding: 14px 0 14px 12px;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
}

.my_textarea {
    width: 772px;
    height: 96px;
    background: #FFFFFF;
    outline: none;
    border: none;
    font-family: 'Century Gothic', serif;
    font-size: 16px;
}


.terms_block {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
}

.terms_block input {
    margin-right: 12px;
}

.checked_error {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #b70000;
    cursor: pointer;
    transition: .5s all ease;
}

.yellow {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #E2B778;
    margin-left: 12px;
    cursor: pointer;
}
