.header_wrapper {
    background: #ECECEC;
}

.static_column {
    width: 74px;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(236, 236, 236, 0.07);
    z-index: 2;
}

.logo {
    margin-left: 100px;
    margin-top: 22px;
    margin-bottom: 62px;
}

.nav_item {
    display: flex;
    width: 100%;
    height: 72px;
    align-items: center;
    cursor: pointer;
}

.layout {
    z-index: 3;
}

.img_block {
    width: 74px;
    height: 100%;
}

.all_rights {
    position: absolute;
    bottom: 12px;
    left: 98px;
    font-family: 'Georgia', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #BCBEC3;
}

.yellow {
    color: #E2B778;
}

.left {
    color: #000;
    display: flex;
    align-items: center;
}

.right {
    color: #000;
    height: 32px;
    display: flex;
    align-items: center;
}

.dropdown_language {
    display: flex;
    align-items: center;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #131313;
    cursor: pointer;
    margin-right: 20px;
}

.welcome_block {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
}

.caps {
    text-transform: uppercase;
}

.chat_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
}

.btn_header {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #BCBEC3;
    cursor: pointer;
}

.btn_block {
    display: flex;
    align-items: center;
    padding: 6px 0;
}
