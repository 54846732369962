.my_select_wrapper {
    margin: 0!important;
    border: none;
    background: #fff;
    border-radius: 5px;
    font-family: 'Century Gothic', serif;
}

.my_select {
    border: none;
    padding: 0;
    margin: 0;
    font-family: 'Century Gothic', serif;
    height: 100%;
}
