.title {
    font-family: 'Georgia', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #131313;
    margin-bottom: 40px;
}

.subtitle {
    width: 70%;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 40px;
}

.input_block {
    margin-right: 20px;
}

.input_title {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    margin-bottom: 12px;
}

.my_input {
    width: 376px;
    height: 48px;
    background: #FFFFFF;
    outline: none;
    border: none;
    font-family: 'Century Gothic', serif;
}

.inputs_wrapper {
    display: flex;
    margin-bottom: 20px;
}

.btn_block {
    margin-top: 40px;
}
