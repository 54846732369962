.date_picker {
    height: 100%;
}

.field_style {
    height: 48px;
    background: #FFF;
}

.field_style div {
    height: 48px;
}
